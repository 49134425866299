import { Layout } from '@components/layout';
import { StructuredData } from '@components/layout';
import {
    Hero,
    Find,
    Mission,
    Study,
    Pillars,
    Wings,
    Paths,
    Start,
    Students,
    Offer,
    Contact,
    PaymentModal,
    Counter,
    Faq,
    BlogSlider,
    Unavailable,
    Partners,
} from '@components/standard';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useRef } from 'react';

const IndexPage = ({ pageContext, location }) => {
    const { page, productsWithDetails } = pageContext;
    const paymentModalRef = useRef();
    const { WP } = useStaticQuery(graphql`
        query {
            WP {
                page(id: "cG9zdDoxNg==") {
                    ACF_FaqHome {
                        questions {
                            a
                            q
                        }
                    }
                }
                googleReviews {
                    averrage
                    reviewsCount
                    reviewsList {
                        nodes {
                            author
                            content
                            rating
                        }
                    }
                }
                category(id: "dGVybToxNjE=") {
                    posts {
                        nodes {
                            id
                            title
                            slug
                            featuredImage {
                                node {
                                    sourceUrl(size: SHOP_THUMBNAIL)
                                    srcSet
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const { googleReviews } = WP;
    const { questions } = WP.page.ACF_FaqHome;
    const { averrage, reviewCount } = googleReviews;
    const { posts } = WP.category;

    return (
        <Layout {...page}>
            <Hero />
            <Find />
            <Mission />
            <Study />
            <Pillars />
            <Wings />
            <Paths />
            <Unavailable />
            <Counter />
            <Start />
            <Students />
            <Partners />
            <Faq questions={questions} />
            <Offer products={productsWithDetails} ref={paymentModalRef} />
            <Contact />
            <BlogSlider posts={posts} />
            <PaymentModal ref={paymentModalRef} />
            <StructuredData>
                {{
                    '@context': 'http://schema.org/',
                    '@type': 'Product',
                    name: 'Mentoring indywidualny',
                    image: 'https://lha.api.localhost-group.com/wp-content/uploads/2021/02/favicon.png',
                    description:
                        'Każdemu studentowi w Akademii zapewniamy dopasowany do jego potrzeb program rozwoju. Uczysz się praktycznych umiejętności w idealnym dla siebie tempie.',
                    sku: ' ',
                    brand: {
                        '@type': 'Thing',
                        name: 'Localhost Group',
                        url: 'https://academy.localhost-group.com',
                    },
                    review: {
                        '@type': 'Review',
                        reviewRating: {
                            '@type': 'Rating',
                            ratingValue: '5',
                        },
                        author: {
                            '@type': 'Person',
                            name: 'Kacper Frankowski',
                        },
                        reviewBody:
                            'Można w tym miejscu zdobyć wiele cennej wiedzy, która nie zniknie nam w głowie tylko sensownie przełożymy ją na wykonywane zadania. Polecam szczególnie osobą które zaczynają dopiero przygodę z programowaniem oraz tym którzy już coś potrafią.',
                    },
                    aggregateRating: {
                        '@type': 'AggregateRating',
                        ratingValue: averrage,
                        reviewCount: reviewCount,
                    },
                    offers: {
                        '@type': 'Offer',
                        priceCurrency: 'PLN',
                        price: 1000,
                        priceValidUntil: '2022-01-25',
                        url: 'https://academy.localhost-group.com/#pricing',
                        availability: 'http://schema.org/InStock',
                        seller: {
                            '@type': 'Organization',
                            name: 'Localhost Group',
                            logo: 'http://lha.api.localhost-group.com/wp-content/uploads/2021/02/favicon.png',
                        },
                    },
                }}
            </StructuredData>
            <StructuredData>
                {{
                    '@context': 'http://schema.org/',
                    '@type': 'Product',
                    name: 'Ścieżki rozwoju',
                    image: 'https://lha.api.localhost-group.com/wp-content/uploads/2021/02/favicon.png',
                    description:
                        'Roadmapa to kompletny i spisany w prostej formie program rozwoju w Akademii, którym prowadzimy naszych studentów. Głównym celem roadmapy jest odzwierciedlanie rynkowej ścieżki rozwoju, dodatkowo rozwijając uniwersalne skille programistyczne (myślenie o rozwiązywaniu problemów) i koderskie (jakość wytwarzanego kodu).',
                    sku: ' ',
                    brand: {
                        '@type': 'Thing',
                        name: 'Localhost Group',
                        url: 'https://academy.localhost-group.com',
                    },
                    review: {
                        '@type': 'Review',
                        reviewRating: {
                            '@type': 'Rating',
                            ratingValue: '5',
                        },
                        author: {
                            '@type': 'Person',
                            name: 'Lyczek',
                        },
                        reviewBody:
                            'Świetnie zaprojektowane roadmapy, które wnoszą duż…aczone w zrozumiały sposób. Zdecydowanie polecam!',
                    },
                    aggregateRating: {
                        '@type': 'AggregateRating',
                        ratingValue: 5,
                        reviewCount: 43,
                    },
                    offers: {
                        '@type': 'Offer',
                        priceCurrency: 'PLN',
                        price: 149,
                        priceValidUntil: '2022-01-25',
                        url: 'https://academy.localhost-group.com/roadmaps#pricing',
                        availability: 'http://schema.org/InStock',
                        seller: {
                            '@type': 'Organization',
                            name: 'Localhost Group',
                            logo: 'http://lha.api.localhost-group.com/wp-content/uploads/2021/02/favicon.png',
                        },
                    },
                }}
            </StructuredData>
        </Layout>
    );
};

export default IndexPage;
